$darkColor: "#161616";
$primary: "#61A670";
$white: "#fff";

.form {
  & label {
    font-weight: 400;
  }
}

.section_card {
  border-radius: 5px;
  padding: 0.8rem 1rem 2rem 1rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.section_number {
  background-color: #74facf;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 0.938rem;

  & h4 {
    color: "black";
  }
}

.input {
  padding: 1rem 0;
  height: 2rem;
  font-size: 0.8rem;
  border-radius: 4px;
  border: 1px solid #8d8d8d;

  [type="text"] {
    padding: 1rem 0.8rem;
    box-sizing: border-box;
  }
}

.input_large {
  width: 18vw;
}

.select_input {
  min-width: 10vw;
}

.button_confirm {
  background-color: $primary;
  color: $white;
}

.button_cancel {
  color: $darkColor;
  border-color: $darkColor;
}

.rotate {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: rotatekf;
  animation-timing-function: linear;
}

@keyframes rotatekf {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.confirm_model_style {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}
