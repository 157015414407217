.appDrawer {
  margin-top: 80px !important;
}

.navDropdown {
  position: fixed;
  top: 60px;
  min-width: 207px;
  padding: 12px 24px;
  z-index: 1000;
}

.navDropdownClosed {
  left: 210px;
}

.navDropdownOpen {
  left: 320px;
}

.selectedIcon {
  color: blue;
}
