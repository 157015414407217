.brandcard {
  flex: 1;
  width: 17rem;
  position: relative;
  background: white;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  gap: 2rem;
  transform: all 1s;
  z-index: 2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-sizing: content-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      top: 0;
      left: 0;
      height: 100%;
      width: 5px;
      background: rgb(145, 145, 145);
      z-index: 1;
    }
  }
}

.active-brandcard {
  padding-right: 3rem;

  &:before {
    content: "";
    position: absolute;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background: black;
    z-index: 1;
    transition: all 0.5s linear;
  }
}

.submenu {
  visibility: hidden;
  max-height: 78.847px;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background: white;
  // max-height: 90px;
  // position: absolute;
  // top: 0;
  // left: 100%;
  z-index: 3;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.active-productcard {
  padding: 1rem 1rem;
  max-height: 100%;
  visibility: visible;
  display: flex;

  & + div {
    display: none;
  }
}

.MuiFormControlLabel-label {
  white-space: nowrap;
}

.MuiTextField-root {
  background-color: white;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}

.rs-stack {
  padding: 0rem 0;
  margin: 0;
  font-family: Open Sans;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
}
