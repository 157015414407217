@tailwind base;
@tailwind components;
@tailwind utilities;

.pageContainer {
  padding: 2.6vh 2.62vw !important;
}

.tabsContainer {
  padding: 2rem 1.5rem;
}

.titleIcon {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  align-items: flex-end;

  cursor: pointer;
  color: "#00FFCC";
}

.innerFrameContainer {
  min-height: calc(100vh - 70px);
  margin-top: 70px;
}

.innerFrameContainerTabs {
  min-height: calc(100vh - 70px);
  margin-top: 70px;
}

.innerFrameContainerClosed {
  margin-left: 64px;
  transition: margin-left 0.25s;
}

.innerFrameContinerOpen {
  margin-left: 320px;
  transition: margin-left 0.25s;
}

.innerFrameContent {
  width: 100%;
}

.ComparisonValueGreen {
  color: #61a670;
}

.ComparisonValueRed {
  color: #ce0b0b;
}

.rs-btn-primary {
  color: #3498ff;
  background-color: aliceblue;

  &:hover {
    color: aliceblue;
    background-color: #3498ff;
  }
}

@media (max-width: 1355px) {
  html {
    min-width: 1380px;
  }
  body {
    min-width: 1380px;
  }
}

.recharts-tooltip-wrapper {
  z-index: 9 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  padding: 0 0px !important;
}
